.tabs {
  overflow: hidden;

  margin-bottom: 1rem;

  border-radius: $border-radius;

  font-size: 0;

  @media screen and (min-width: 62rem) {
    border-bottom: 0.25rem solid $color-dark-gray;
    border-radius: 0;
  }
}

.tabs-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.tab {
  display: block;

  background-color: $color-light-gray;

  font-size: 1rem;

  @media screen and (min-width: 62rem) {
    display: inline-block;
    overflow: hidden;

    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    + .tab {
      margin-left: 0.5rem;
    }
  }
}

.tab-selected {
  color: $color-white;
  background-color: $color-dark-gray;

  font-weight: 500;
}

.tab-link {
  display: block;

  padding: 0.75rem 1rem;

  text-decoration: none !important;

  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .tab-selected & {
    color: $color-white;
  }
}
