.progress-bar {
  position: relative;
  z-index: $z-progress-bar;

  margin: 1rem 2rem 1rem 1rem;
  padding: 2rem 0;
}

.progress-bar-labeled {
  padding: 2rem 0;
}

.progress-bar-background {
  position: relative;
  z-index: $z-progress-bar-background;

  display: block;

  width: 100%;
  height: 1rem;
  margin: 0;
  padding: 0;

  border-radius: $border-radius;
  background-color: $color-light-gray;
}

.progress-bar-progress {
  position: absolute;
  z-index: $z-progress-bar-progress;
  top: 50%;
  left: 0;

  display: block;

  width: 100%;
  height: 1rem;
  margin: -0.5rem 0 0;
  padding: 0;

  border-radius: $border-radius;
  background-color: $color-gray;
}

.progress-bar-tick {
  position: absolute;
  z-index: $z-progress-bar-tick;
  top: 50%;

  display: block;

  width: 2rem;
  height: 2rem;
  margin-top: -1rem;
  margin-left: -1rem;

  border: 0.25rem solid #f9f9f9;
  border-radius: 50%;
  background-color: $color-light-gray;
}

.progress-bar-tick-label {
  position: absolute;
  top: 0;

  display: block;

  width: 20%;
  margin-left: -10%;

  text-align: center;

  &:nth-child(odd) {
    top: initial;
    bottom: 0;
  }
}

.progress-bar-progress-caution {
  background-color: $color-caution;
}

.progress-bar-progress-danger {
  background-color: $color-danger;
}

.progress-bar-progress-info {
  background-color: $color-info;
}

.progress-bar-progress-success {
  background-color: $color-success;
}

.progress-bar-progress-warning {
  background-color: $color-warning;
}

.progress-bar-tick-caution {
  border-color: $color-caution;
}

.progress-bar-tick-danger {
  border-color: $color-danger;
}

.progress-bar-tick-info {
  border-color: $color-info;
}

.progress-bar-tick-success {
  border-color: $color-success;
}

.progress-bar-tick-warning {
  border-color: $color-warning;
}

.progress-bar-tick-active {
  background-color: $color-primary;
}
