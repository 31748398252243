body {
  position: relative;
  z-index: $z-body;

  min-width: 61.25rem; // TODO get rid of this to go responsive
  margin: 0;
  padding: 0;

  color: $color-primary;
  border-top: 0.25rem solid $color-secondary;
  background-color: #f9f9f9;

  font-family: $font-family-lato;
  font-size: $font-size-root;
  font-weight: 400;
  font-style: normal;
}

// HEADER

.header {
  position: relative;
  z-index: $z-body-header;

  padding: 0.75rem 0;

  background-color: $color-white;
}

.header-inner {
  position: relative;

  display: flex;

  max-width: 62rem;
  margin-right: auto;
  margin-left: auto;

  justify-content: space-between;
  align-items: center;
}

.header-inner-top-banner {
  position: relative;

  display: inline-block;

  padding: 0.5rem 1rem;

  &:active {
    top: 0.0625rem;
  }
}

.header-inner-top-banner-image {
  width: 24rem;
}

.header-inner-top-links {
  padding: 0;

  list-style: none;

  vertical-align: middle;
}

.header-inner-top-links-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.header-inner-top-links-list-element {
  display: inline-block;

  &:after {
    display: inline-block;

    content: '\00b7';

    color: $color-light-gray-dark;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.header-inner-top-links-list-element-link {
  display: inline-block;

  padding: 0 1rem;

  font-weight: 600;
}

.nav {
  position: relative;
  z-index: $z-body-nav;

  margin-bottom: 1rem;

  background-color: $color-light-gray;
}

.nav-inner {
  position: relative;

  display: flex;

  max-width: 62rem;
  margin-right: auto;
  margin-left: auto;

  align-items: stretch;
  justify-content: space-between;
}

.nav-inner-left {
  display: inline-block;
}

.nav-inner-right {
  display: inline-block;
  float: right;
}

.nav-inner-list {
  margin: 0;
  padding: 0;

  list-style: none;

  li {
    position: relative;
  }

  > li {
    display: inline-block;

    color: $color-gray;

    > a {
      display: block;

      padding: 0.75rem 1rem;

      color: $color-dark-gray;

      font-weight: 600;

      &:active,
      &:hover {
        text-decoration: none !important;

        color: $color-dark-gray-dark;
        background-color: $color-light-gray-dark;
      }

      &:focus {
        text-decoration: none !important;
      }
    }
  }
}

.nav-inner-list-dropdown {
  position: absolute;
  top: 100%;
  left: 0;

  visibility: hidden;

  width: 14rem;
  margin: 0;
  padding: 0;

  list-style: none;

  transition: opacity 0.15s ease-in-out;

  opacity: 0;
  color: $color-white;
  background-color: $color-dark-gray;

  line-height: 1.5;

  > li {
    &:hover {
      background-color: $color-dark-gray-dark;
    }

    > a {
      display: block;

      padding: 0.75rem;

      text-decoration: none;

      color: $color-white;
    }
  }

  .nav-inner-list-dropdown {
    top: 0;
    left: 100%;

    border-right: 0;
    border-left: 0.125rem solid $color-dark-gray-dark;
  }

  .nav-inner-right & {
    right: 0;
    left: auto;

    .nav-inner-list-dropdown {
      right: 100%;
      left: auto;

      border-right: 0.125rem solid $color-dark-gray-dark;
      border-left: 0;
    }
  }

  .nav-inner-list li:hover > & {
    visibility: visible;

    transition: opacity 0.15s ease-in-out;

    opacity: 1;
  }
}

.content {
  position: relative;
  z-index: $z-body-content-wrapper;

  margin-bottom: 1rem;
}

.content-inner {
  position: relative;

  display: flex;

  max-width: 62rem;
  margin-right: auto;
  margin-left: auto;

  flex-wrap: wrap;
}

.content-inner-left {
  padding: 0 1rem;

  border-right: 0.125rem solid $color-secondary;

  flex-basis: 15rem;
  flex-grow: 0;
}

.content-inner-left-logo {
  margin-bottom: 1rem;

  text-align: center;
}

.content-inner-left-logo-image {
  width: 12rem;
}

.content-inner-left-links {
}

.content-inner-left-links-list {
  margin: 0;
  padding: 0;

  list-style: none;

  > li {
    margin-bottom: 1rem;
    padding-top: 1rem;

    border-top: 0.125rem solid $color-light-gray;

    > h2 {
      margin-bottom: 0.5rem;

      font-family: $font-family-oswald;
      font-size: 1.375rem;
      font-weight: 400;
      line-height: 1.2;
    }

    > ul {
      margin: 0 0 0.75rem;
      padding: 0;

      list-style: none;

      > li {
        margin: 0 0 0.25rem;
        padding: 0;
      }
    }
  }
}

.content-inner-left-leagues {
}

.content-inner-left-leagues-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.content-inner-left-leagues-list-element {
  margin-bottom: 1rem;
  padding-top: 1rem;

  border-top: 0.125rem solid $color-light-gray;
}

.content-inner-left-leagues-list-element-header {
  margin-bottom: 0.5rem;

  font-family: $font-family-oswald;
  font-size: 1.375rem;
  line-height: 1.2;
}

.content-inner-left-leagues-list-element-divisions {
}

.content-inner-left-leagues-list-element-divisions-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.content-inner-left-leagues-list-element-divisions-list-element {
  margin-bottom: 0.5rem;
}

.content-inner-left-leagues-list-element-divisions-list-element-header {
  margin-bottom: 0.25rem;

  font-family: $font-family-oswald;
  font-size: 1.125rem;
  font-weight: 400;
}

.content-inner-left-leagues-list-element-divisions-list-element-links {
}

.content-inner-left-leagues-list-element-divisions-list-element-links-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.content-inner-left-leagues-list-element-divisions-list-element-links-list-element {
  margin-bottom: 0.25rem;
}

.content-inner-left-leagues-list-element-divisions-list-element-links-list-element-link {
}

.content-inner-right {
  padding: 0 1rem;

  flex-basis: 0;
  flex-grow: 1;
}

.footer {
  position: relative;
  z-index: $z-body-footer;

  padding: 0 1rem;

  color: $color-gray;
}

.footer-inner {
  position: relative;

  max-width: 62rem;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;

  text-align: center;

  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background-color: $color-light-gray;
}
