.page-division-players-players {
  margin-bottom: 1rem;
}

.page-division-players-players-list {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;

  flex-wrap: wrap;
  justify-content: space-between;
}

.page-division-players-players-list-item {
  width: calc(50% - 0.5rem);
  padding: 1rem;

  border-radius: $border-radius;

  &:nth-child(4n-2),
  &:nth-child(4n-3) {
    background-color: $color-light-gray;
  }
}

.page-division-players-notes {
  overflow: hidden;

  border-radius: $border-radius;
  background-color: $color-light-gray;
}

.page-division-players-notes-title {
  padding: 1rem 0;

  text-align: center;
  text-transform: uppercase;

  color: $color-light-gray;
  background-color: $color-gray;
}

.page-division-players-notes-content {
  padding: 0.5rem 1rem 1rem;
}

.page-division-players-notes-content dl dd {
  margin-bottom: 1rem;
}
