.page-division-teams {
}

.page-division-teams-schedule {
  margin-bottom: 2rem;
}

.page-division-teams-teams {
  display: flex;

  max-width: 75rem;
  margin-bottom: 2rem;

  flex-wrap: wrap;
  justify-content: space-between;
}

.page-division-teams-teams-team {
  width: calc(50% - 0.5rem);
  margin-bottom: 1rem;
}

.page-division-teams-notes {
  overflow: hidden;

  border-radius: $border-radius;
  background-color: $color-light-gray;
}

.page-division-teams-notes-title {
  padding: 1rem 0;

  text-align: center;
  text-transform: uppercase;

  color: $color-light-gray;
  background-color: $color-gray;
}

.page-division-teams-notes-content {
  padding: 0.5rem 1rem 1rem;
}

.page-division-teams-notes-content dl dd {
  margin-bottom: 1rem;
}
