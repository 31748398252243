.page-registration-export {
}

.page-registration-export-header {
  margin-bottom: 2rem;
}

.page-registration-export-section {
  margin-bottom: 3rem;
}

.page-registration-export-section-header {
  margin-bottom: 1rem;
}
