.message {
  margin-bottom: 1rem;
  padding: 1rem;

  color: $color-gray-dark;
  border: 0.1875rem solid transparent;
  border-color: $color-light-gray-dark;
  border-radius: $border-radius;
  background-color: $color-light-gray;

  &.message-small {
    padding: 0.5rem;

    border-width: 0.125rem;

    font-size: $font-size-sm;
  }

  &.message-success {
    color: $color-white;
    border-color: $color-success-dark;
    background-color: $color-success;

    hr {
      border-top-color: $color-success-dark;
    }
  }

  &.message-caution {
    color: $color-white;
    border-color: $color-caution-dark;
    background-color: $color-caution;

    hr {
      border-top-color: $color-caution-dark;
    }
  }

  &.message-warning {
    color: $color-white;
    border-color: $color-warning-dark;
    background-color: $color-warning;

    hr {
      border-top-color: $color-warning-dark;
    }
  }

  &.message-danger {
    color: $color-white;
    border-color: $color-danger-dark;
    background-color: $color-danger;

    hr {
      border-top-color: $color-danger-dark;
    }
  }

  &.message-info {
    color: $color-white;
    border-color: $color-info-dark;
    background-color: $color-info;

    hr {
      border-top-color: $color-info-dark;
    }
  }

  > a:not(.button) {
    color: $color-white !important;
    text-decoration: underline;

    &:hover {
      color: rgba($color-white, 0.75) !important;
    }
  }
}
