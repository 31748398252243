.schedule-generation-page-form-fields {
  display: flex;

  margin-bottom: 1rem;
}

.schedule-generation-page-form-fields-input {
  margin-right: 0.5rem;

  flex: 1;

  > select {
    display: block;

    width: 100%;
  }
}

.schedule-generation-page-form-fields-help {
  margin-left: 0.5rem;

  color: $color-gray;

  flex: 1;
}
