.button {
  display: inline-block;

  padding: 0.75rem 1.5rem;

  cursor: pointer;
  user-select: none;
  transition: background-color 150ms ease;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;

  color: $color-white;
  border: 0;
  border-radius: $border-radius;
  background-color: $color-primary;

  font-size: 1rem;
  font-weight: 700;

  appearance: none;
  -webkit-font-smoothing: antialiased;

  &:active {
    text-decoration: none;
  }

  &:focus,
  &:hover {
    text-decoration: none;

    color: $color-white;
    background-color: $color-primary-dark;
  }

  &:disabled {
    cursor: not-allowed;

    opacity: 0.5;
  }

  &.button-small {
    padding: 0.5rem 1rem;

    font-size: $font-size-sm;
  }

  &.button-primary {
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &.button-secondary {
  }

  &.button-success {
    background-color: $color-success;

    &:hover {
      background-color: $color-success-dark;
    }
  }

  &.button-caution {
    background-color: $color-caution;

    &:hover {
      background-color: $color-caution-dark;
    }
  }

  &.button-warning {
    background-color: $color-warning;

    &:hover {
      background-color: $color-warning-dark;
    }
  }

  &.button-danger {
    background-color: $color-danger;

    &:hover {
      background-color: $color-danger-dark;
    }
  }

  &.button-info {
    background-color: $color-info;

    &:hover {
      background-color: $color-info-dark;
    }

    &.button-inverse {
      color: $color-info;
      background-color: $color-white;

      &:hover {
        color: $color-info-dark;
        background-color: $color-white;
      }
    }
  }

  &.button-link {
    padding: 0.75rem 0.5rem;

    color: $color-blue !important;
    background-color: transparent;

    &:active {
      position: relative;
      top: 0.0625rem;
    }

    &:hover {
      color: $color-blue-dark !important;
      background-color: transparent;
    }
  }

  &.button-block {
    display: block;

    width: 100%;
  }
}

a.button {
  color: $color-white;
}
