$spacer: 1rem;
$spacer-x: 1rem;
$spacer-y: 1rem;
$border-width: 0.0625rem;

$border-radius: 0.25rem;

$font-size-root: 16px;
$font-size-base: 1rem;
$font-size-xl: 1.5rem;
$font-size-lg: 1.25rem;
$font-size-sm: .875rem;
$font-size-xs: .75rem;

$line-height-base: 1.25;

$font-size-h1: 2rem;
$font-size-h2: 1.5rem;
$font-size-h3: 1.25rem;
$font-size-h4: 1rem;
$font-size-h5: 0.875rem;
$font-size-h6: 0.75rem;

$small-font-size: 80%;

$hr-color: rgba(0, 0, 0, 0.1);
$hr-thickness: $border-width;


$headings-margin-bottom: $spacer;
$headings-font-family: $font-family-oswald;
$headings-font-weight: 400;
$headings-line-height: 1.2;
$headings-color: inherit;
