@mixin icon($icon) {
  @include fa-icon;
  @extend .fa;
  @extend .fa-#{$icon}:before;
}

@mixin sr-only {
  position: absolute;

  overflow: hidden;
  clip: rect(0,0,0,0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;
}
