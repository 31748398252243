.header-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.header-list-header {
  padding: 0.5rem;

  background-color: $color-light-gray;

  display: flex;
}

.header-list-header-left {
  flex: 1;
}

.header-list-header-right {
  text-align: right;

  flex: 1;
}

.header-list-inner {
  margin: 0 0 1rem 0;
  padding: 0;

  list-style: none;

  > li {
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
  }
}

.selection-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.selection-list-item {
  & + & {
    border-top: 0.125rem solid $color-light-gray;
  }
}

.selection-list-item-link {
  position: relative;

  display: block;

  width: 100%;
  padding: 1rem;

  &:hover {
    background-color: $color-light-gray;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 0;

    width: 2rem;
    height: 2rem;
    margin-top: -1.25rem;

    content: "\203A";
    text-align: center;

    font-size: 2rem;
    font-weight: 300;
    font-weight: bold;
    line-height: 2rem;
  }
}

.no-spacing-list {
  margin: 0;
  padding: 0 0 0 1.1875rem;
}
