.button-social-twitter {
  background-color: $color-social-twitter;

  @extend .button;

  &:hover {
    background-color: $color-social-twitter-dark;
  }
}

.button-social-facebook {
  background-color: $color-social-facebook;

  @extend .button;

  &:hover {
    background-color: $color-social-facebook-dark;
  }
}

.social-icon {
  display: inline-block;

  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.25rem;

  text-align: center;

  border-radius: $border-radius;

  font-size: 1.25rem;
  line-height: 2.5rem;

  &:active {
    position: relative;
    top: 0.0625rem;
  }
}

.social-icon-facebook {
  color: $color-white;
  background-color: $color-social-facebook;

  &:hover {
    color: $color-white;
    background-color: $color-social-facebook-dark;
  }
}

.social-icon-twitter {
  color: $color-white;
  background-color: $color-social-twitter;

  &:hover {
    color: $color-white;
    background-color: $color-social-twitter-dark;
  }
}

.social-icon-text {
  @include sr-only();
}
