body {
  line-height: $line-height-base;
}

a {
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
  text-decoration: none;

  color: $color-blue;
}

a:focus,
a:hover {
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
  text-decoration: underline;

  color: $color-blue-dark;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;

  color: $headings-color;

  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h1,
.h1 {
  font-size: $font-size-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
}

small,
.small {
  font-size: $small-font-size;
}

strong {
  font-weight: 700;
}

hr {
  padding-top: $spacer-y;
  margin-bottom: ($spacer-y * 2);

  border: 0;
  border-bottom: $hr-thickness solid $hr-color;
}

.subdued {
  color: $color-gray-dark;
}
