.page-player-survey {
}

.page-player-survey-table {
  overflow: hidden;

  border-radius: $border-radius;
}

.page-player-survey-table-cell {
}

.page-player-survey-table-cell-field {
  padding: 0.5rem;
}

.page-player-survey-table-cell-field-error {
  color: $color-white;
  background-color: $color-red;
}
