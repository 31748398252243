.alerts {
  position: fixed;
  z-index: $z-body-alerts;
  top: 0;
  left: 50%;

  overflow: hidden;

  width: 48rem;
  margin-left: -24rem;

  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.alerts-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.alerts-list-element {
}

.alert {
  padding: 1rem;

  &.alert-info {
    color: $color-white;
    border-color: $color-info-dark;
    background-color: $color-info;
  }

  &.alert-success {
    color: $color-white;
    border-color: $color-success-dark;
    background-color: $color-success;
  }

  &.alert-caution {
    color: $color-white;
    border-color: $color-caution-dark;
    background-color: $color-caution;
  }

  &.alert-warning {
    color: $color-white;
    border-color: $color-warning-dark;
    background-color: $color-warning;
  }

  &.alert-error, &.alert-danger {
    color: $color-white;
    border-color: $color-danger-dark;
    background-color: $color-danger;
  }
}
