.page-static-notes {
  overflow: hidden;

  border-radius: $border-radius;
  background-color: $color-light-gray;
}

.page-static-notes-title {
  padding: 1rem 0;

  text-align: center;
  text-transform: uppercase;

  color: $color-light-gray;
  background-color: $color-gray;
}

.page-static-notes-content {
  padding: 0.5rem 1rem 1rem;
}
