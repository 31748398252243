.page-game-report-form-scores-input {
  input {
    width: 4rem;

    text-align: center;
  }
}

.page-game-report-scores {
  margin-bottom: 2rem;
}

.page-game-report-spirit {
  margin-bottom: 2rem;
}

.page-game-report-attendance {
  margin-bottom: 2rem;
}

.page-game-report-attendance-table {
}

.page-game-report-attendance-row {
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
}

.page-game-report-attendance-row-element {
  width: calc(33% - 1rem);
}

.page-game-report-attendance-row-element-label {
  display: inline-block !important;

  padding: 0.5rem 0;
}

.page-game-report-comment {
  margin-bottom: 1rem;

  textarea {
    width: 100%;
    height: 4rem;
  }
}
