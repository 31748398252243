.slider {
  margin: 0 0 1rem;
}

.slider input {
  float: right;

  width: 4rem;
  margin-right: 1rem;

  border: none;
  background: transparent;

  font-size: 1.75rem;
  font-weight: bold;
}

.slider .slider_errors {
  color: $color-danger;

  font-weight: bold;
}

.slider-element {
  height: 1rem !important;
  margin: 1.25rem 6rem 1.25rem 1rem;

  border: 0 !important;
  border-radius: $border-radius !important;
  background-color: $color-light-gray;
}

.slider-element .ui-slider-handle {
  top: -0.25rem !important;

  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: -0.75rem !important;

  cursor: grab;

  border: 0 !important;
  border-radius: 50% !important;
  background-color: $color-green;
}

.slider-element .ui-slider-handle:active {
  cursor: grabbing;

  background-color: $color-green-dark;
}

.slider-description {
  display: flex;
}

.slider-description-left {
  padding-left: 1rem;

  text-align: left;

  flex: 1;
}

.slider-description-right {
  padding-right: 1rem;

  text-align: right;

  flex: 1;
}
