$z-body: 1;
$z-body-alerts: 50;
$z-body-header: 40;
$z-body-nav: 30;
$z-body-content-wrapper: 20;
$z-body-footer: 10;

$z-home-page-news: 1;
$z-home-page-news-overlay: 20;
$z-home-page-news-content: 10;
$z-home-page-events: 1;
$z-home-page-events-overlay: 20;
$z-home-page-events-content: 10;

$z-progress-bar: 1;
$z-progress-bar-background: 10;
$z-progress-bar-progress: 20;
$z-progress-bar-tick: 30;

$z-team-card-game: 1;
$z-team-card-game-divider: 20;
$z-team-card-game-team: 10;
