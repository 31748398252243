.page-home {
}

.page-home-leagues {
  margin-bottom: 1rem;
}

.page-home-leagues-header {
}

.page-home-leagues-content {
}

.page-home-leagues-content-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.page-home-leagues-content-list-element {
  margin-bottom: 1rem;
}

.page-home-leagues-content-list-element-header {
}

.page-home-leagues-content-list-element-list {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;

  flex-wrap: wrap;
  align-items: stretch;
}

.page-home-leagues-content-list-element-list-element {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 14rem;
  margin: 0 1rem 1rem 0;
  padding: 0;

  list-style: none;

  border-left: 0.25rem solid $color-gray;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: $color-light-gray;

  justify-content: space-between;
}

.page-home-leagues-content-list-element-list-element-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.page-home-leagues-content-list-element-list-element-background-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba($color-dark-gray, 0.9), rgba($color-dark-gray, 0.5));
}

.page-home-leagues-content-list-element-list-element-content {
  position: relative;

  display: block;

  padding: 0.25rem 0;

  color: $color-primary;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    color: $color-primary-dark;
  }

  .page-home-leagues-content-list-element-list-element-background + & {
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }
}

.page-home-leagues-content-list-element-list-element-content-tag {
  position: relative;

  display: inline-block;

  margin-bottom: 1rem;
  padding: 0.25rem 0.75rem;

  color: white;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: $color-gray;

  font-family: $font-family-oswald;
  font-size: $font-size-sm;
  font-weight: 200;
}

.page-home-leagues-content-list-element-list-element-content-heading {
  position: relative;

  margin-right: 0.5rem;
  margin-bottom: 1rem;
  padding: 0 1.25rem 0 0.5rem;

  font-family: $font-family-oswald;
  font-weight: 200;

  &:after {
    position: absolute;
    top: 50%;
    right: 0rem;

    width: 1rem;
    height: $font-size-sm;
    margin-top: -$font-size-sm / 2;

    text-align: right;

    color: $color-gray;

    font-size: $font-size-sm !important;
    line-height: $font-size-sm !important;

    @include icon('chevron-right');

    .page-home-leagues-content-list-element-list-element-content:active &,
    .page-home-leagues-content-list-element-list-element-content:focus &,
    .page-home-leagues-content-list-element-list-element-content:hover & {
      color: $color-dark-gray;
    }
  }

  .page-home-leagues-content-list-element-list-element-background + .page-home-leagues-content-list-element-list-element-content > & {
    color: $color-white !important;
    text-shadow: 0 0.0625rem 0 $color-dark-gray-dark;

    &:after {
      color: $color-white;
    }
  }
}

.page-home-leagues-content-list-element-list-element-content-info {
  position: relative;

  margin-bottom: 1rem;
  padding: 0 0.5rem;

  color: $color-gray-dark;

  font-size: 0;

  span {
    display: inline-block;

    font-size: $font-size-sm;
    font-weight: 400;

    &:after {
      padding: 0 0.25rem;

      content: '\00b7';
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  .page-home-leagues-content-list-element-list-element-background + .page-home-leagues-content-list-element-list-element-content > & {
    color: $color-white !important;
    text-shadow: 0 0.0625rem 0 $color-dark-gray-dark;
  }
}

.page-home-leagues-content-list-element-list-element-footer {
  position: relative;
}

.page-home-leagues-content-list-element-list-element-footer-message {
  display: block;

  padding: 0.5rem;

  color: rgba($color-gray, 0.6);

  font-style: italic;
}

.page-home-leagues-content-list-element-list-element-footer-action {
  display: block;

  padding: 1rem 0.5rem;

  text-align: left;

  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &:after {
    position: absolute;
    top: 50%;
    right: 0.5rem;

    height: $font-size-sm;
    margin-top: -$font-size-sm / 2;

    text-align: right;

    color: $color-white;

    font-size: $font-size-sm !important;
    line-height: $font-size-sm !important;

    @include icon('chevron-right');
  }
}

.page-home-info {
  display: flex;

  margin-bottom: 1rem;

  > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.page-home-info-news {
  flex: 3;
}

.page-home-info-events {
  flex: 2;
}

.page-home-info-section {
  margin-bottom: 1rem;
}

.page-home-info-section-header {
}

.page-home-info-section-content {
}

.page-home-info-section-content-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.page-home-info-section-content-list-element {
  overflow: hidden;

  margin-bottom: 0.5rem;
  padding: 0;
}

.page-home-info-section-content-list-element-link {
  position: relative;

  display: block;

  padding: 1rem 1.5rem 1rem 1rem;

  text-decoration: none !important;

  border-left: 0.25rem solid $color-blue;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: $color-light-gray;

  .page-home-info-news & {
  }

  .page-home-info-events & {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  }

  &:after {
    position: absolute;
    top: 50%;

    height: 1rem;
    margin-top: -0.5rem;

    color: $color-light-gray-dark;

    @include icon('chevron-right');

    .page-home-info-news & {
      right: 1rem;
    }

    .page-home-info-events & {
      right: 0.5rem;
    }
  }

  &:focus,
  &:hover,
  &:active {
    border-color: $color-blue-dark;

    &:after {
      color: $color-gray;
    }
  }
}

.page-home-info-section-content-list-element-link-content {
  color: $color-dark-gray;

  font-weight: 600;

  .page-home-info-news & {
    font-size: 1.125rem;
  }

  .page-home-info-events & {
    font-size: $font-size-sm;
  }
}

.page-home-info-section-content-list-element-link-footer {
  color: $color-gray;

  .page-home-info-news & {
    font-size: $font-size-sm;
  }

  .page-home-info-events & {
    font-size: $font-size-xs;
  }
}

.page-home-info-section-footer {
  font-size: $font-size-sm;
}

.page-home-follow {
  margin-bottom: 2rem;
}

.page-home-follow-content {
  display: flex;

  margin-right: auto;
  margin-left: auto;

  > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .fa {
    margin-right: 0.5rem;
  }
}

.page-home-follow-content-announcements {
  flex: 2;
}

.page-home-follow-content-twitter {
  flex: 1;
}

.page-home-follow-content-facebook {
  flex: 1;
}
