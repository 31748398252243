.team-card {
  overflow: hidden;

  border-radius: $border-radius;
}

.team-card-header {
  background-color: $color-gray;
}

.team-card-header-name {
  margin-bottom: 0;
  padding: 1rem;

  background-color: $color-gray;

  font-size: $font-size-lg;
  font-weight: 700;
}

.team-card-header-info {
  display: flex;

  padding: 0.5rem 1rem;

  background-color: $color-gray-dark;

  font-size: $font-size-sm;
  font-weight: 500;
}

.team-card-header-info-color {
  flex: 2;
}

.team-card-header-info-email {
  flex: 1;

  .team-card-header-info-color + & {
    text-align: right;
  }
}

.team-card-content {
  padding: 0.5rem 1rem 1rem;

  background-color: $color-light-gray;
}

.team-card-content-members {
  margin: 0;
  padding: 0;

  list-style: none;

  line-height: 1.25;
}

.team-card-content-members-member {
  padding-bottom: 0.125rem;
}

.team-card-content-members-member-email {
  float: right;

  font-size: $font-size-sm;
}

.team-card-footer {
  background-color: $color-light-gray;
}

.team-card-footer-heading {
  margin-bottom: 0.5rem;
  padding: 0.5rem;

  text-align: center;
  text-transform: uppercase;

  color: $color-light-gray;
  background-color: $color-gray;

  font-size: $font-size-xs;
}

.team-card-footer-content {
  padding: 0 1rem 1rem;

  text-align: center;

  color: $color-gray;

  font-size: $font-size-sm;
}

.team-card-game {
  position: relative;
  z-index: $z-team-card-game;
  display: flex;
}

.team-card-game-divider {
  position: absolute;
  z-index: $z-team-card-game-divider;
  top: 50%;
  left: 50%;

  width: 4rem;
  height: 4rem;
  margin: -2rem 0 0 -2rem;

  text-align: center;
  text-transform: uppercase;

  color: $color-white;
  border-radius: 50%;
  background-color: $color-light-gray-dark;

  font-size: $font-size-xl;
  font-weight: 900;
  line-height: 4rem;
}

.team-card-game-team {
  position: relative;
  z-index: $z-team-card-game-team;

  padding: 0 1rem;
  flex: 1;

  &:first-child {
    padding-left: 0;

    .team-card-content {
      padding-right: 1.5rem;
    }
  }

  &:last-child {
    padding-right: 0;

    .team-card-content {
      padding-left: 1.5rem;
    }

    .team-card-content-members-member-email {
      margin-right: 0;
    }
  }
}
