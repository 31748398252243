.page-user-index-next-game {
}

.page-user-index-next-game-info {
  text-align: center;

  font-weight: 600;
}

.page-user-index-next-game-info-date {
  margin-bottom: 0.25rem;

  font-size: $font-size-lg;
}


.page-user-index-next-game-info-field {
  margin-bottom: 0.75rem;
}

.page-user-index-following-game {
  text-align: center;
}

.page-user-index-registrations-note {
  color: $color-gray;
}
