$color-pink: #ee6fa0;
$color-pink-dark: #c7507e;
$color-red: #e74c3c;
$color-red-dark: #c0392b;
$color-orange: #e67e22;
$color-orange-dark: #d35400;
$color-yellow: #f1c40f;
$color-yellow-dark: #d7ad04;
$color-green: #2ecc71;
$color-green-dark: #27ae60;
$color-turquoise: #1abc9c;
$color-turquoise-dark: #16a085;
$color-blue: #3498db;
$color-blue-dark: #2980b9;
$color-purple: #9b59b6;
$color-purple-dark: #8e44ad;
$color-light-gray: #ecf0f1;
$color-light-gray-dark: #bdc3c7;
$color-gray: #95a5a6;
$color-gray-dark: #7f8c8d;
$color-dark-gray: #34495e;
$color-dark-gray-dark: #2c3e50;
$color-black: #000000;
$color-white: #ffffff;
$color-off-white: #f9f9f9;

// States
$color-primary: $color-dark-gray;
$color-primary-dark: $color-dark-gray-dark;
$color-secondary: $color-blue;
$color-secondary-dark: $color-blue-dark;
$color-success: $color-green;
$color-success-dark: $color-green-dark;
$color-caution: $color-yellow;
$color-caution-dark: $color-yellow-dark;
$color-warning: $color-orange;
$color-warning-dark: $color-orange-dark;
$color-danger: $color-red;
$color-danger-dark: $color-red-dark;
$color-info: $color-blue;
$color-info-dark: $color-blue-dark;

// Social
$color-social-facebook: #2f4b93;
$color-social-facebook-dark: darken(#2f4b93, 10%);
$color-social-twitter: #00bdef;
$color-social-twitter-dark: darken(#00bdef, 10%);
